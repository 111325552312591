<template>
  <div>
    <div class="card" style="margin-top: 1rem; margin-bottom: 0">
      <div class="card-header d-flex flex-lg-row flex-column" style="padding: 0;">
        <div class="media align-items-center data-description m-r-auto" style="display: flex;">
          <figure class="media-left">
            <p class="image is-48x48" v-if="!actionLink.length">
              <img :src="result.icon" :alt="result.param">
            </p>
            <p class="image is-48x48" v-else>
              <a :href="actionLink" target="_blank" v-tippy="{ placement : 'top',  arrow: true }"
                 :content="$t('contact.gotoapp')+ ' ' + app">
                <img :src="result.icon" :alt="result.param">
              </a>
            </p>
          </figure>
          <div class="media-content" style="width: 100%">
            <div class="content">
              <h2 v-if="!actionLink.length">
                {{ typeof result.source_locales !== 'undefined' ? result.source_locales[locale] : result.source }}: {{ result.param === 'image' ? '' : result.value }}
              </h2>
              <h2 v-else>
                <a :href="actionLink" v-tippy="{ placement : 'top',  arrow: true }"
                   :content="$t('contact.gotoapp')+ ' ' + result.source" target="_blank">
                  {{ typeof result.source_locales !== 'undefined' ? result.source_locales[locale] : result.source }}: {{result.value }}
                </a>
                <a :href="actionLink" target="_blank" v-tippy="{ placement : 'top',  arrow: true }"
                   :content="$t('contact.gotoapp')+ ' ' + app"><i class="feather icon-external-link"></i></a>
              </h2>
            </div>
          </div>
        </div>
        <div class="contact-control pb-2 pb-xl-0">

          <i
              v-if="typeof result.additional_options !== 'undefined' || typeof result.additional_search !== 'undefined' && canUpdateLocator"
              :id="result.value"
              style="margin-left: 0.4rem;margin-right: 0.4rem; font-size: 20px; cursor: pointer"
              @click.self="updateSource(result)"
              :content="$t('result.update_result')"
              v-tippy="{ placement : 'top',  arrow: true }"
              class="feather icon-refresh-cw"
              :class="{'rotating' : rotate }"
          />
          <i
              :id="result.value"
              style="margin-left: 0.4rem;margin-right: 0.4rem;font-size: 20px; cursor: pointer"
              v-if="result.source !== 'GetContact'"
              @click.self="editMode(paginatedItems)"
              :content="$t('result.edit_result')"
              v-tippy="{ placement : 'top',  arrow: true }"
              class="feather icon-edit"
              :class="{'editButtonActive': editResults}"
          >
          </i>
          <i
              style="margin-left: 0.4rem;margin-right: 1rem; font-size: 20px; cursor: pointer"
              @click="deleteSelectedResult"
              class="feather icon-x"
              :content="$t('result.delete_source')"
              v-tippy="{ placement : 'top',  arrow: true }"
          />
        </div>

      </div>


      <div style="display: flex; justify-content: flex-start;align-items: center; margin: 2px 18px; align-items: center">
        <div>
          <span v-html="$tc('result.total_find', result.total_count, {n: numberWithCommas(result.total_count)} )"></span>
          <span v-if="result.search_results.length > 1">
            <span>, </span>
            <span  v-html="$tc('result.total_show', result.search_results.length , {n : numberWithCommas(result.search_results.length)})"></span>
          </span>
          <span v-else-if="result.search_results.length === 1">
             <span>, </span>
            <span  v-html="$tc('result.one_show', result.search_results.length , {n : numberWithCommas(result.search_results.length)})"></span>
          </span>

        </div>
        <b-button :disabled="loading" variant="primary" style="margin-left: 8px; margin-right: 8px" block @click="servicePagination(result)" v-if="canPaginate()">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="margin-right: 8px;"
               v-if="loading"></span>
          <span class="buttTitle">{{$t('result.show_more')}} </span>
        </b-button>
      </div>


      <div class="card-block" style="margin: 0.5em !important; padding-bottom: 1rem !important;">
        <div class="search-photo-container" v-if="result.param === 'image'">
          <image-profile :payload="result.value"/>
        </div>
        <template v-if="searchResults">
          <nav class="panel" v-for="(infoResult, index) in searchResults.search_results" :key="index" :id="index">
  
            <div class="row flex-column-reverse flex-lg-row">
              <div>
                <div class="panel-heading" :id="index" v-if="infoResult.info_date"><span class="contact-result-date">{{ setCorrectDate(infoResult.info_date) }}</span>
                  <span v-if="resultDateSource(infoResult) === 'online'" class="label-inverse-primary label-addition" >{{resultDateState(infoResult)}}</span>
                  <span v-else class="label-inverse-info-border label-addition" >{{resultDateState(infoResult)}}</span>
                </div>
                <InfoBlock v-for="(param, i) in infoResult.data" :key="i" :id="index"
                              v-if="(!['longitude', 'latitude'].includes(param.param))"
                              :parentItems="infoResult.data"
                           :dataParam="param" :resultTitle="result.param" :resultSource="result.source" />
              </div>
              <div class="col-lg-7" v-if="typeof infoResult.photos !== 'undefined' && infoResult.photos.length">
                <CardFooter :photos="infoResult.photos" :meta="meta" :source="result.source" />
              </div>
  
              <template v-else-if="result.source === 'InternetArchive'">
                <internet-archive v-for="(infoResult, index) in paginatedItems" :key="index" :result="infoResult"></internet-archive>
              </template>
              <template v-else-if="result.source !== 'GetContact'">
                <nav class="panel" v-for="(infoResult, index) in paginatedItems" :key="index" >
                  <div class="panel-heading" v-if="infoResult.info_date" style="display: flex; align-items: center">
                    <div >
                      <b-form-checkbox
                          style="width: 15px; height: 15px; margin-right: 0.5rem;padding: 0;padding-top: 1px"
                          v-model="infoResult.selected"
                          v-if="editResults"
                          @input="markCurrentElement()"
                      >
                      </b-form-checkbox>
                    </div>
                    <div style="display: flex; align-content: center;justify-content: space-between; width: 100%;align-items: center">
                      <div style="line-height: 1.2rem" >
                        <span class="contact-result-date">{{ setCorrectDate(infoResult.info_date)  }}</span>
                        <span v-if="resultDateSource(infoResult) === 'online'" class="label-inverse-primary label-addition" >{{resultDateState(infoResult)}}</span>
                        <span v-else class="label-inverse-info-border label-addition" >{{resultDateState(infoResult)}}</span>
                      </div>
                      <div v-if="editResults" class="media-right" @click="removeSelectedItem(infoResult)">
                        <i :content="$t('result.remove_element')" v-tippy="{ placement : 'top',  arrow: true }" class="feather icon-x"></i>
                      </div>
                    </div>
  
  
                  </div>
                  <div class="row flex-column-reverse ">
  
                    <div>
                      <InfoBlock v-for="(param, i) in infoResult.data" :key="i" :dataParam="param"
                                 :parentItems="infoResult.data"
                                 :resultTitle="result.param"
                                 :resultSource="result.source"  />
                      <relationsBlock v-if="!isUndefined(infoResult.relation_counters)" :relations="infoResult.relation_counters"></relationsBlock>
                    </div>

                    <div v-if="typeof infoResult.photos !== 'undefined' && infoResult.photos.length">
                      <CardFooter :photos="infoResult.photos" :meta="meta"/>
                    </div>
                  </div>
                </nav>
              </template>
            </div>
  
          </nav>
        </template>
        <template v-else>
          <div v-if="show" :id="'pagination_'+index">
            <b-pagination
                style="margin: 0.5rem"
                align="center"
                v-if="result.search_results && result.search_results.length > 3"
                :total-rows="total.length"
                last-number

                v-model="current"
                :per-page="perPage"
                order="is-centered"
                @input="onChange"
            >
            </b-pagination>
            <div class="contact-controls-buttons" v-if="editResults">
              <b-button class="button" variant="danger"   @click="removeSelectedItems()">{{ $t("result.delete_selected") }}</b-button>
              <div style="display:flex; justify-content: start; width: 100%">
                <b-button v-if="result.search_results && result.search_results.length > 3" class="button" variant="primary"  @click="selectAll(true)" style="margin-right: 8px">{{ $t("result.select_all") }}</b-button>
                <b-button v-if="result.search_results && result.search_results.length > 3" class="button" variant="secondary"  @click="selectAll(false)">{{ $t("result.remove_all") }}</b-button>
              </div>
            </div>
  
            <template v-if="result.source === 'InternetArchive'">
              <internet-archive v-for="(infoResult, index) in paginatedItems" :key="index" :result="infoResult"></internet-archive>
            </template>
  
            <template v-else-if="result.source === 'Instagram'">
  
              <nav class="panel" v-for="(infoResult, index) in instagramDate" :key="index" >
                <div class="panel-heading" v-if="infoResult.info_date" style="display: flex; align-items: center">
                  <div >
                    <b-form-checkbox
                        style="width: 15px; height: 15px; margin-right: 0.5rem;padding: 0;padding-top: 1px"
                        v-model="infoResult.selected"
                        v-if="editResults"
                        @input="markCurrentElement()"
                    >
                    </b-form-checkbox>
                  </div>
                  <div style="display: flex; align-content: center;justify-content: space-between; width: 100%;align-items: center">
                    <div style="line-height: 1.2rem" >
                      <span class="contact-result-date">{{ setCorrectDate(infoResult.info_date)  }}</span>
                       <span v-if="resultDateSource(infoResult) === 'online'" class="label-inverse-primary label-addition" >{{resultDateState(infoResult)}}</span>
                       <span v-else class="label-inverse-info-border label-addition" >{{resultDateState(infoResult)}}</span>
                    </div>
                    <div v-if="editResults" class="media-right" @click="removeSelectedItem(infoResult)">
                      <i :content="$t('result.remove_element')" v-tippy="{ placement : 'top',  arrow: true }" class="feather icon-x"></i>
                    </div>
                  </div>
  
  
                </div>
                <div class="row flex-column-reverse ">
  
                  <div>
                    <InfoBlock v-for="(param, i) in infoResult.data" :key="i" :dataParam="param"
                               :resultTitle="result.param"
                               :resultSource="result.source"  />
                    <relationsBlock v-if="!isUndefined(infoResult.relation_counters)" :relations="infoResult.relation_counters"></relationsBlock>
                  </div>
                  <div v-if="typeof infoResult.photos !== 'undefined' && infoResult.photos.length">
                    <CardFooter :photos="infoResult.photos" :meta="meta"/>
                  </div>
                </div>
              </nav>
  
            </template>
  
            <template v-else>
              <nav class="panel" v-for="(infoResult, index) in paginatedItems" :key="index" >
                <div class="panel-heading" v-if="infoResult.info_date" style="display: flex; align-items: center">
                  <div >
                    <b-form-checkbox
                        style="width: 15px; height: 15px; margin-right: 0.5rem;padding: 0;padding-top: 1px"
                        v-model="infoResult.selected"
                        v-if="editResults"
                        @input="markCurrentElement()"
                    >
                    </b-form-checkbox>
                  </div>
                  <div style="display: flex; align-content: center;justify-content: space-between; width: 100%;align-items: center">
                    <div style="line-height: 1.2rem" >
                      <span class="contact-result-date">{{ setCorrectDate(infoResult.info_date) }}</span>
                      <span v-if="resultDateSource(infoResult) === 'online'" class="label-inverse-primary label-addition" >{{resultDateState(infoResult)}}</span>
                      <span v-else class="label-inverse-info-border label-addition" >{{resultDateState(infoResult)}}</span>
                    </div>
                    <div v-if="editResults" class="media-right" @click="removeSelectedItem(infoResult)">
                      <i :content="$t('result.remove_element')" v-tippy="{ placement : 'top',  arrow: true }" class="feather icon-x"></i>
                    </div>
                  </div>
  
  
                </div>
                <div class="row flex-column-reverse ">
  
                  <div>
                    <InfoBlock v-for="(param, i) in infoResult.data" :key="i" :dataParam="param" 
                              v-if="(!['longitude', 'latitude'].includes(param.param))"
                               :parentItems="infoResult.data"
                               :resultTitle="result.param"
                               :resultSource="result.source"
                               :meta="meta"
                    />
                    <relationsBlock v-if="!isUndefined(infoResult.relation_counters)" :relations="infoResult.relation_counters"></relationsBlock>
                  </div>
                  <div v-if="typeof infoResult.photos !== 'undefined' && infoResult.photos.length">
                    <CardFooter :photos="infoResult.photos" :meta="meta"/>
                  </div>
                </div>
              </nav>
            </template>
  
          </div>
  
        </template>
      </div>
    </div>
    <b-modal ref="delete-modal" hide-footer centered :title="deleteModalTitle" :size="showGraph ? 'lg' : 'md' ">
      <div class="delete-body">
        <graph
            v-if="showGraph"
            :itemsForDelete="itemsForDelete"
            :disableDelete="true"
        />
      </div>
      <div class="d-grid">
        <b-button class="mt-3" variant="danger" block @click="deleteResult">{{ $t("modal.actionDelete") }}</b-button>
        <b-button class="mt-2" variant="secondary" block @click="close">{{ $t('modal.cancel') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Graph from "@/components/results/graph";
import CardFooter from "@/components/results/CardFooter";
import InfoBlock from "@/components/results/InfoBlock";
import ImageProfile from "./ImageProfile";
import resultsToLevels from "@/mixins/resultsToLevels";
import resultDateState from "@/mixins/resultDateState";
import deleteSearchResultsController from "@/mixins/deleteSearchResultsController"
import InternetArchive from "@/components/InternetArchive.vue";
import GraphTooltip from "@/components/results/graphTooltip";
import moment from "moment";
import filters from "@/mixins/filters";
import relationsBlock from '@/components/relationsBlock.vue'
import {mapGetters} from "vuex";
import {isUndefined} from "underscore";
export default {
  props: ['result', 'meta','parentSources','item','index'],
  mixins:[resultsToLevels, resultDateState, deleteSearchResultsController, filters],
  components: {
    GraphTooltip,
    InternetArchive,
    ImageProfile,
    CardFooter,
    InfoBlock,
    Graph,
    relationsBlock
  },
  setup() {
    return {
      moment,
      isUndefined
    }
  },
  data() {
    return {
      searchResults: null,
      current: 1,
      perPage: 3,
      show: true,
      showModalDialog: false,
      showModalDialogForSearchResult: false,
      deleteModalTitle: '',
      itemsForDelete: [],
      itemsForDeleteSearchedResult: [],
      editResults : false,
      payload: {
        keyVal: this.meta.key,
        result: this.result
      },
      showGraph: false,
      rotate: false,
      instagramDate: [],
      loading: false
    }
  },

  computed:{
    ...mapGetters('ui', ['servicesNames', 'sourcesNames']),
    ...mapGetters('ui', ['services']),
    total() {
      let arr = [];
      if(this.result.search_results) {
        this.result.search_results.forEach(searchResult => {
          searchResult.type = this.result.source_type
          // searchResult.type = this.result.type
          arr.push(searchResult)
        })
      }
      return arr;
    },
    canUpdateLocator(){
      if (this.result.source === 'Locator'){
        let locator = this.services.find(item=> item.name === 'Locator')
        return typeof locator !== 'undefined';
      } else {
        return true
      }
    },
    paginatedItems() {
      let page_number = this.current-1
      return this.total.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
    },
    results() {
      if (this.$route.name === 'Result' || this.$route.name === 'movementHistoryResult'){
        return this.$store.state.contacts.results.results
      } else {
        return this.$store.state.contacts.childResults.results
      }
    },
    locale() {
      return this.$i18n.locale === 'ua' ? 'uk': this.$i18n.locale
    },
    actionLink() {
      if(this.result.source === 'WhatsApp' && this.result.param === 'isdn') {
        return 'https://wa.me/' + this.result.value.replace(/\+/, '');
      } else if((this.result.source === 'Telegram' || this.result.source.toLowerCase().includes("telegram")) && this.result.param === 'isdn') {
        if(this.result.param !== 'telegram_id') {
          return 'https://t.me/' + this.result.value;
        }
        return '';
      } else if(this.result.source === 'Viber' && this.result.param === 'isdn') {
        return 'viber://chat?number='+ encodeURIComponent(this.result.value);
      }
      return '';
    },
    app() {
      if(this.result.source === 'Viber' && this.result.param === 'isdn') {
        return 'Viber'
      } else if(this.result.source === 'WhatsApp' && this.result.param === 'isdn') {
        return 'WhatsApp'
      } else if((this.result.source === 'Telegram' || this.result.source.toLowerCase().includes("telegram")) && this.result.param === 'isdn') {
        return 'Telegram';
      }
      return '';
    },
  },
  methods: {
    numberWithCommas(x) {
      let parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
    canPaginate(){
      if (this.result.search_results && this.result.search_results_count < this.result.total_count){
        if (this.result.source_type === 'online' && this.servicesNames.includes(this.result.source)){
          return true
        } else if (this.result.source_type !== 'online' && this.sourcesNames.includes(this.result.source)){
          return true
        } else {
          return false
        }
      }
    },

    servicePagination(result){
      let payload = {
        search_request_id: result.request_id,
        service_id: result.source_id,
        value: this.item.value,
        key: this.meta.key,
        payload: {
          limit: 10,
          offset: result.search_results_count ? result.search_results_count : result.search_results.length,
        }
      }

      if (this.meta.payload.param !== 'full_search'){
        if (result.source_type === 'online'){
          this.loading = true;
          this.$store.dispatch('contacts/servicePagination', payload).then(() =>{
            this.$store.dispatch('contacts/paginationEventExist', true)
            this.loading = false;
            this.highlight();
          }).catch(e => {
            const message = e.response.data.message;
            if(message === 'Access denied.') {
              this.loading = false;
              this.showToast();
            }
          })
        } else {
          this.loading = true;
          this.$store.dispatch('contacts/sourcePagination', payload).then(() =>{
            this.$store.dispatch('contacts/paginationEventExist', true)
            this.loading = false;
            this.highlight();
          }).catch(e => {
            const message = e.response.data.message;
            if(message === 'Access denied.') {
              this.loading = false;
              this.showToast();
            }
          })
        }
      } else {
        if (result.source_type === 'online'){
          this.loading = true;
          this.$store.dispatch('contacts/filteredSearchServicePagination', payload).then(() =>{
            this.$store.dispatch('contacts/paginationEventExist', true)
            this.loading = false;
            this.highlight();
          }).catch(e => {
            const message = e.response.data.message;
            if(message === 'Access denied.') {
              this.loading = false;
              this.showToast();
            }
          })
        } else {
          this.loading = true;
          this.$store.dispatch('contacts/filteredSearchSourcePagination', payload).then(() =>{
            this.loading = false;
            this.highlight();
          }).catch(e => {
            const message = e.response.data.message;
            if(message === 'Access denied.') {
              this.loading = false;
              this.showToast();
            }
          })
        }
      }

    },

    showToast(){
        this.$toast.error(this.$t("contact.access_denied"), {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
    },



    highlight(){
      let element = document.getElementById('pagination_'+this.index);
      element.parentElement.classList.add('highlighted')
      setTimeout(()=>{
        element.parentElement.classList.remove('highlighted')
      }, 1000)
    },
    setCorrectDate(date){
      // let temp;
      // if (date[2] === '.' && date.length === 10){
      //   date = moment(date, 'DD.MM.YYYY')
      // } else if(Number.isInteger(date)) {
      //   date = moment(date, 'X')
      // } else {
      //   return date
      // }
      // if (date.hours() == 0 && date.minutes() == 0 ) {
      //   temp = date.format('DD.MM.YYYY');
      // } else {
      //   temp = date.format('DD.MM.YYYY, HH:mm')
      // }
      // return temp
      let text;
      if (Number.isInteger(date)){
          text = moment(date, 'X').format('DD.MM.YYYY, HH:mm')
      } else if (date.length === 10 && date.includes('-')){
          text = moment(date, 'YYYY-MM-DD').isValid() 
              ? moment(date, 'YYYY-MM-DD').format('HH:mm') === '00:00' ?
              moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY') :
              moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY HH:mm')
              : date

      } else if(date.length === 10 && date.includes('.')) {
          text = moment(date, 'DD.MM.YYYY').isValid() 
              ? moment(date, 'DD.MM.YYYY').format('HH:mm') === '00:00' ?
              moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY') :
              moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')
              : date
      } else {
          if (!moment(date).isValid()){
              text = date;
              if(moment(date, 'DD.MM.YY HH:mm:ss').isValid()) {
                text = moment(date, 'DD.MM.YY HH:mm:ss').format('DD.MM.YYYY HH:mm');
              }
          } else {
              text = moment(date).format('HH:mm') === '00:00' ?
                  moment(date).format('DD.MM.YYYY') :
                  moment(date).format('DD.MM.YYYY HH:mm');
          }
      }
      return text;
    },

    markCurrentElement(){
      this.updateResult()
    },
    selectAll(state){
      if(this.result.search_results) {
        this.result.search_results.forEach(item => {
          item.selected = state
        })
      }
      this.updateResult()
    },



    updateResult(){
      this.editResults = !this.editResults
      this.editResults = !this.editResults
    },
    deleteResult(){
      let payload = {
        keyVal: this.meta.key,
        result: this.result
      }
      this.$store.dispatch('contacts/setRemoveSourceResult', payload).then(()=>{
        // this.$store.dispatch('contacts/updateResult', payload);
      })
      if (this.itemsForDelete.length ){
        this.results.forEach(result =>{
          this.itemsForDelete.forEach(item=>{
            if (result.value === item.value){
              let payload = {
                keyVal: this.meta.key,
                result: result
              }
              this.$store.dispatch('contacts/setRemoveSourceResult', payload).then(()=>{
                // this.$store.dispatch('contacts/updateResult', payload);
              })
            }
          })
        })
      }
    },
    close(){
      this.$refs['delete-modal'].hide();
    },
    onChange(){
      this.show = false;
      this.$nextTick(()=>{
        this.show = true;
      })
    },

    setDialogMessage(itemsForDelete){
      let temp = []
      itemsForDelete.forEach(item => {
        if (!temp.length){
          temp.push(item.value)
        } else {
          if (temp.indexOf(item.value) === -1){
            temp.push(item.value)
          }
        }
      })
      let message;
      if (this.result.level !== 3 && itemsForDelete && itemsForDelete.length){
        let tempMessage = this.$t('graph.dialog_alert_text')
        temp.forEach((item, index)=>{
          if (index === temp.length -1){
            tempMessage = tempMessage+item
          } else {
            tempMessage = tempMessage+item+', '
          }
        })
        message = tempMessage + this.$t('graph.continue');
      } else {
        message = this.$t('graph.remove_result');
      }
      this.deleteModalTitle = message
      return message;
    },
    editMode(){
      this.editResults = !this.editResults;
    },
    updateSource(result){
      this.rotate = true;
      let payload = {
        keyVal: this.meta.key,
        result: this.result
      }
      let id;
      if (typeof result.additional_options !== 'undefined'){
        id = result.additional_options.service_id
      } else {
        id = result.additional_search.service_id
      }
      let obj = {
        payload: {
          'param': result.param,
          'value': result.value,
        },
        service_id: id,
        source: result.source,
        key: this.meta.key
      }
      this.$store.dispatch('contacts/updateSource', obj).finally(()=>{
        this.rotate = false;
        this.$store.dispatch('contacts/updateResult', payload);
        this.checkInstagram();
        this.updateResult()
      })
    },


    deleteSelectedResult(){
      this.showModalDialog = true;
      this.itemsForDelete = [];
      if (this.result.level !== 3 ){
        let childLevel = this.levels[this.result.level]
        if (typeof childLevel !== 'undefined'){
          childLevel.forEach(child =>{
            if (child.parentSources.includes(this.result.source)){
              if (child.parentSources.length === 1){
                if (this.result.value === child.parentValue){
                  this.itemsForDelete.push(child)
                }
                if (typeof child.children !== 'undefined' &&  child.children.length > 0 ){
                  let childLevel = this.levels[child.level]
                  childLevel.forEach(childOfChild =>{
                    if (childOfChild.parentSources.length === 1 && child.value === childOfChild.parentValue){
                      this.itemsForDelete.push(childOfChild)
                    }
                  })
                }
              }
            }
          })
        }
      }
      this.setDialogMessage(this.itemsForDelete)
      // this.setDialogMessage(this.itemsForDelete);
      if (typeof this.itemsForDelete !== 'undefined' && this.itemsForDelete.length){
        this.showGraph = true;
      }
      this.$refs['delete-modal'].show();
      try {
        this.$matomo.trackEvent('Web Edit Result', 'WasClicked');
      } catch (e) {
        console.log('ignore error');
      }
    },
    checkInstagram(){
        this.instagramDate = [];

        let newDate = [];
        let temp = this.result.search_results;
        let index = 0

        temp.forEach((item, innerIndex) =>{
          // index = innerIndex
          // item.data.forEach(dataItem =>{
          //   if (dataItem.param === 'subscriptions'){
          //     subscriptions = subscriptions + dataItem.value +', '
          //   } else if (dataItem.param === 'followers'){
          //     followers = followers + dataItem.value +', '
          //   }
          // })


          newDate =  item.data.filter(el =>  el.param !== 'subscriptions' && el.param !== 'followers' )
          const subscriptions = item.data.filter(el => el.param === 'subscriptions');
          const followers = item.data.filter(el => el.param === 'followers')

          if (subscriptions.length){
            newDate.push({
              param: 'subscriptions',
              value: subscriptions.map(item => item.value).join(', ')
            })
          }
          if (followers.length){
            newDate.push({
              param: 'followers',
              value: followers.map(item => item.value).join(', ')
            })
          }
          temp[innerIndex].data = newDate

        })

      this.instagramDate = temp
    },
    removeLastComma(str){
      let n=str.lastIndexOf(",");
      str = str.substring(0,n)
      return str
    },
    getContactSort() {
      if(this.result.source === 'GetContact' && this.result.search_results.length > 1) {
        let results = [];
        let photos = [];
        for (let searchItem of this.result.search_results) {
          let items = searchItem.data.map(item => {
            return Object.assign(item, {date: searchItem.info_date})
          })
          if (searchItem.photos.length){
            searchItem.photos.forEach(photo =>{
              photos.push(photo)
            })
          }
          results.push(...items);
        }
        const dynamicSort = (property) => {
          let sortOrder = 1;
          if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
          }
          return function (a,b) {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
          }
        };
        results = results.sort(dynamicSort('date'))
        results = results.filter((item,pos) => {
          return results.findIndex(x => x.value === item.value) === pos
        })

        this.searchResults = Object.assign({}, this.result, {
          search_results: [
            {
              data: results,
              photos: photos,
              info_date: results[results.length -1].date
            }
          ]
        })
      }
    }
  },
  mounted() {
    this.getContactSort();
    if (this.result.source === "Instagram"){
      this.checkInstagram();
    }
  }
}
</script>
<style lang="scss">
.highlighted{
  animation: colorChange 700ms;
}

@keyframes colorChange {
  0% {
    background-color: $primary;
  }
  100%{
    background-color: #fbfbfb;
  }
}
.buttTitle{
  text-transform: none;
}
.delete-modal{
  .modal-content{
    width: auto !important;
  }
}
</style>
<style lang="scss" scoped>




.editButtonActive{
  color: rgb(64, 153, 255);
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.m-r-auto {
  margin-right: auto;
}
.card-header{
  padding: 10px 20px;
}
.card-block{
  padding: 0.5em !important;
}
.media{
  padding: 10px;
  //margin-bottom: 0.5em;
}
.collapse{
  padding: 10px;
}
.panel-block{
  padding-left: 10px;
  padding-right: 10px;
  line-height: 2em;
  display: flex;
  justify-content: space-between;
}
.panel-block:not(:last-child){
  border-bottom: 1px solid #ededed;
}
.panel{
  //padding-bottom: 15px;
}

.media-left{
  margin-bottom: 0;
  margin-right: 8px;
}
.image{
  img {

    width: 48px;
    height: 48px;
    @media (max-width: 922px) {
      width: 24px;
      height: 24px;
    }
  }
}
h2 {
  margin-top: 10px;
  font-size: 1.2em;
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
  a {
    font-size: inherit;
  }
}
.panel-heading{
  background-color: #ededed;
  border-radius: 6px 6px 0 0;
  color: #363636;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
  text-align: left;
  font-size: .75em;
}
.card-block{
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  font-size: 1rem;
  padding: 0 !important;
}

.contact-control {
  display: flex;
  justify-content: flex-end;
  align-items: center
}

.contact-controls-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 0.5rem;
}

.contact-controls-buttons button {
  margin-bottom: 0.5rem;
  width: 25%;
  text-transform: none;
}

.contact-controls-buttons button:last-child {
  width: 25%;
}

.media-left {
  width: 24px;
  height: 24px;
}
.media-left img {
  width: 24px;
  height: 24px;
}

@media (max-width: 992px) {
  .media-left img {
    width: 24px;
    height: 24px;
  }
  .contact-controls-buttons {
    flex-direction: column-reverse;
  }
  .contact-controls-buttons button, .contact-controls-buttons button:last-child {
    width: 100%;
  }
  .contact-control {
    width: 100%;
    flex: 1 0 100%;
    justify-content: center;
    margin-top: 5px;
  }
  .media-left {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
  }
  .media-content {
    flex: 1 1 50%;
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      word-break: break-word;
    }
  }
  .data-description {
    flex-wrap: wrap;
  }
  .content h2 {
    font-size: 1.2em;
  }
}

.label-addition {
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
}
.contact-result-date {
  margin-right: 1rem;
  @media (max-width: 767px) {
    margin-bottom: 10px;
    line-height: 1.5rem;
  }
}
</style>