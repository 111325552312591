import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import Result from '../views/Result'
import movementHistoryResult from "@/views/movementHistoryResult";
import ChildResult from "@/views/ChildResult";
import env from "../configs/env.json";
import verification from "@/views/verification";
import androidDevices from "@/views/androidDevices/androidDevices";
Vue.use(VueRouter)
const prefix = env.prefix;

const routes = [
  {
    path: prefix+'',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: prefix+'result',
  //   name: 'Result',
  //   component: Result,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: prefix+'result/:id',
    name: 'Result',
    component: Result,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+'movementHistoryResult/:id',
    name: 'movementHistoryResult',
    component: movementHistoryResult,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Child',
    path: prefix+'result/child/:id',
    component: ChildResult,
    meta: {
      requiresAuth: true
    },
  },
  {
    name: 'Photo',
    path: prefix+'result/photo/:id',
    component: ChildResult,
    meta: {
      requiresAuth: true
    },
  },
  {
    name: 'Monitoring',
    path: prefix+'monitoring',
    component: () => import('../views/Monitoring'),
    meta: {
      requiresAuth: true,
      requiredMonitoring: true
    }
  },
  {
    name: 'MonitoringLocation',
    path: prefix+'MonitoringLocation',
    component: () => import('../views/MonitoringLocation'),
    meta: {
      requiresAuth: true,
      requiredMonitoringLocation: true
    }
  },
  {
    path: prefix+'login',
    name: 'Login',
    component: () => import('../views/Login'),
  },
  {
    path: prefix+'settings',
    name: 'Settings',
    component: () => import('../views/Settings'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+"settings/admin/users",
    name: "Users",
    component: () => import('../views/UsersTable'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+"settings/admin/users/:id/edit",
    name: "UserEdit",
    component: () => import('../views/UserEdit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+"settings/admin/users/create",
    name: "UserCreate",
    component: () => import('../views/UserCreateForm'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+"settings/admin/users/:id",
    name: "UserDetail",
    component: () => import('../views/UserDetail'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+"settings/admin/search-processing",
    name: "SearchProcessing",
    component: () => import('../views/admin/SearchProcessing'),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: prefix+"settings/admin/base-source-date",
  //   name: "BaseSourceDate",
  //   component: () => import('../views/admin/BaseSourceDate'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },

  {
    path: prefix+"settings/Billing",
    name: "Billing",
    component: () => import('../views/Billing'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: prefix+"settings/admin/Logs",
    name: "Logs",
    component: () => import('../views/admin/Logs'),
    meta: {
      requiresAuth: true,
    }
  },


  {
    path: prefix+"settings/admin/stats",
    name: "SearchRequests",
    component: () => import('../views/admin/SearchRequests'),
    meta: {
      requiresAuth: true,
      getStats: true
    }
  },
  {
    path: prefix+"settings/admin/stats/DatabasesStats",
    name: "DatabasesStats",
    component: () => import('../views/admin/DatabasesStats'),
    meta: {
      requiresAuth: true,
      getStats: true
    }
  },
  {
    path: prefix+"settings/description",
    name: "Description",
    component: () => import('../views/Description'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+"settings/description-bases",
    name: "DescriptionBases",
    component: () => import('../views/DescriptionBase'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+"settings/changelog",
    name: "ServerChangelog",
    component: () => import('../views/changelog'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+"settings/change-telegram-id",
      name: "ChangeTelegramId",
      component: () => import('../views/settings/AccountTelegram'),
      meta: {
        requiresAuth: true
      }
  },
  {
    path: prefix+'raw-result',
    name: 'RawResult',
    component: () => import('../views/RawResult'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+'admin/keys',
    name: "Keys",
    component: () => import('../views/Keys'),
    meta: {
      requiresAuth: true
    }
  },
    {
      path: prefix+'groups',
      name: "Groups",
      component: () => import('../views/Groups'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: prefix+'groups/create',
      name: "GroupCreate",
      component: () => import('../views/GroupCreate'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: prefix+'groups/:id',
      name: "GroupEdit",
      component: () => import('../views/GroupEdit'),
      meta: {
        requiresAuth: true
      }
    },

  {
    path: prefix+'services',
    name: "Services",
    component: () => import('../views/Services'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+'services/create',
    name: "ServiceCreate",
    component: () => import('../views/ServiceCreate'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+'services/:id',
    name: "ServiceGroupEdit",
    component: () => import('../views/ServiceGroupEdit'),
    meta: {
      requiresAuth: true
    }
  },



  {
    path: prefix+'settings/user-groups/create',
    name: "User Group",
    component: () => import('../views/UserGroupCreate'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+'settings/user-groups',
    name: "User Group list",
    component: () => import('../views/UserGroupsList'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+"settings/user-groups/:id",
    name: "UserGroupEdit",
    component: () => import('../views/UserGroupEdit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+"grafana-oauth",
    name: "GrafanOauth",
    component: () => import('../views/GrafanaAuth'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+'androidDevices',
    name: 'androidDevices',
    component: androidDevices,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+'verification',
    name: 'verification',
    component: () => import('../views/verification'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+'verification-add',
    name: 'VerificationAdd',
    component: () => import('../views/VerificationAdd'),
  },
  {
    path: prefix+'documentation',
    name: 'Documentation',
    component: () => import('../views/Swagger.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+'tenants/:id/edit',
    name: 'TenantEdit',
    component: () => import('../components/tenants/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+'map',
    name: 'Map',
    component: () => import('../views/Map.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: prefix+'location/:id',
    name: 'Location',
    component: () => import('../views/Location.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']) {
      if (to.matched.some(record => record.meta.requiredMonitoring)) {
        if (!store.getters['auth/permissions'].includes('monitoring.online_status')) {
          next('/')
        }
      }
      if (to.matched.some(record => record.meta.requiredMonitoringLocation)) {
        if (!store.getters['auth/permissions'].includes('monitoring.location')) {
          next('/')
        }
      }
      if (to.matched.some(record => record.meta.getStats)) {
        if(!store.getters['auth/isAdmin']) {
          next('/')
        }
      }
      if (to.path === '/result/' || to.path === '/result' || to.path === '/result/child'|| to.path === '/result/child/' || to.path === '/result/photo' || to.path === '/result/photo/'){
        next('/')
      }
      next()
      return
    }
    next('/login')
  } else {
    if (to.path === '/result/' || to.path === '/result' || to.path === '/result/child'|| to.path === '/result/child/' || to.path === '/result/photo' || to.path === '/result/photo/'){
      next('/')
    } else {
      next()
    }
  }
})


export default router
