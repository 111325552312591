<template>
  <div class="navbar-container container-fluid">
    <!--    <ul class="nav-left">-->
    <!--      <li class="header-notification">-->

    <!--        <div class="nav-left d-lg-flex d-none" style="flex-direction: column;padding: 5px 10px; margin: 4px; border: 1px solid white; border-radius: 4px">-->
    <!--          <div style="color: white; display: flex;">-->
    <!--            <div class="rightSide" style="display: flex;flex-direction: column">-->
    <!--              <small class="textShadowRed">{{ $t('users_list_params.account_expiration_date') }}: &nbsp; </small>-->
    <!--              <small v-if="!user.account.unlimited">{{ $t('users_list_params.balance') }}: &nbsp;</small>-->
    <!--              <small v-else>{{ $t('users_list_params.balance') }}:  &nbsp;</small>-->
    <!--              <small>{{ $t('users_list_params.limit') }}: &nbsp;</small>-->
    <!--            </div>-->
    <!--            <div class="leftSide" style="display: flex;flex-direction: column">-->
    <!--              <small class="textShadowRed"> {{ user.account.expired_at | moment('DD.MM.YYYY, HH:mm')}}</small>-->
    <!--              <small v-if="!user.account.unlimited"> {{ user.account.balance }}</small>-->
    <!--              <small v-else>{{ $t('users_list_params.unlimited') }}</small>-->
    <!--              <small>{{ user.account.balance }} {{ $t('users_list_params.to') }}  {{user.account.reseted_at| moment('DD.MM.YYYY, HH:mm')}} </small>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <small>{{ $t('users_list_params.account_expiration_date') }}: {{user.account.expired_at | moment('DD.MM.YYYY, HH:mm')}}</small>-->
    <!--          <small v-if="!user.account.unlimited">{{ $t('users_list_params.balance') }}: {{ user.account.balance }}</small>-->
    <!--          <small v-else>{{ $t('users_list_params.balance') }}: {{ $t('users_list_params.unlimited') }}</small>-->
    <!--          <small>{{ $t('users_list_params.limit') }}: {{ user.account.balance }} {{ $t('users_list_params.to') }}  {{user.account.reseted_at| moment('DD.MM.YYYY, HH:mm')}} </small>-->
    <!--        </div>-->
    <!--      </li>-->
    <!--    </ul>-->

    <ul class="nav-right">

      <div class="nav-left d-lg-flex d-none"
        style="flex-direction: column; margin: 2px; border: 1px solid white; border-radius: 4px">
        <div style="color: white; display: flex;">
          <div class="rightSide" style="display: flex;flex-direction: column">
            <small class="textInfo" :class="{'alertText' : balanceAlertCheck, 'errorText': balanceErrorCheck }"
              v-if="!user.account.unlimited">{{ $t('users_list_params.balance') }}: &nbsp;</small>
            <small class="textInfo" v-else>{{ $t('users_list_params.balance') }}: &nbsp;</small>
            <small class="textInfo" :class="{'alertText' : limitAlertCheck, 'errorText': limitErrorCheck  }">{{
              $t('users_list_params.limit') }}: &nbsp;</small>
            <small class="textInfo" :class="{'alertText' : expiredAlertCheck }">{{
              $t('users_list_params.account_expiration_date') }}: &nbsp; </small>
          </div>
          <div class="leftSide" style="display: flex;flex-direction: column">
            <small class="textInfo" :class="{'alertText' : balanceAlertCheck, 'errorText': balanceErrorCheck }"
              v-if="!user.account.unlimited"> {{ user.account.balance }}</small>
            <small class="textInfo" v-else>{{ $t('users_list_params.unlimited') }}</small>
            <small class="textInfo" :class="{'alertText' : limitAlertCheck, 'errorText': limitErrorCheck  }">{{
              limitLeft() }} {{ $t('users_list_params.to') }} {{ limitExpiredDate| moment('DD.MM.YYYY, HH:mm')}}
            </small>
            <small class="textInfo" :class="{'alertText' : expiredAlertCheck }"
              v-if="!this.roles.includes('superadmin')"> {{ user.account.expired_at | moment('DD.MM.YYYY')}}</small>
            <small class="textInfo" v-else> {{ $t('users_list_params.unlimited')}}</small>
          </div>
        </div>

        <!--          <small>{{ $t('users_list_params.account_expiration_date') }}: {{user.account.expired_at | moment('DD.MM.YYYY, HH:mm')}}</small>-->
        <!--          <small v-if="!user.account.unlimited">{{ $t('users_list_params.balance') }}: {{ user.account.balance }}</small>-->
        <!--          <small v-else>{{ $t('users_list_params.balance') }}: {{ $t('users_list_params.unlimited') }}</small>-->
        <!--          <small>{{ $t('users_list_params.limit') }}: {{ user.account.balance }} {{ $t('users_list_params.to') }}  {{user.account.reseted_at| moment('DD.MM.YYYY, HH:mm')}} </small>-->
      </div>
      
      <li class="header-notification d-lg-none d-block" @click="openBalanceModal">
        <img class="iconShadow"
          :class="{'green': iconState === 'success', 'alert':  iconState === 'alert', 'red' : iconState === 'error' }"
          style="width: 20px;height: 20px" :src="prefix+'images/coin.png'">
        <!--        <i class=" feather icon-alert-triangle large"></i>-->
      </li>
      <li class="user-profile header-notification">
        <div class="dropdown-primary dropdown">
          <div class="dropdown-toggle" data-bs-toggle="dropdown">
            <img :src="`${prefix}images/no-photo.jpeg`" class="img-radius" alt="User-Profile-Image">
            <span style="color: white; line-height: 20px; display: inline-block; vertical-align: middle;">
              {{ user.login || '' }}
              <i class="feather icon-more-vertical" style="font-size: 14px; line-height: 16px; margin-left: 0"></i>
            </span>

            <br>
          </div>
          <ul class="show-notification profile-notification dropdown-menu" data-dropdown-in="fadeIn"
            data-dropdown-out="fadeOut">
            <div class="menuHeader hovered" @click="redirect('Billing')">
              <i class="feather icon-credit-card"></i>{{ this.$t('user_menu.billing')}}
            </div>
            <div class="menuHeader hovered" @click="redirect('Settings')">
              <i class="feather icon-settings"></i>{{ this.$t('user_menu.settings')}}
            </div>
            <div v-if="isAdminAccess">
              <div class="menuHeader">
                <i class="feather icon-user"></i>{{ this.$t('user_menu.administration')}}
              </div>
              <div class="element hovered" style="margin-top: 0 !important;" @click="redirect('Users')">
                {{ this.$t('user_menu.users')}}
              </div>
              <div class="element hovered" @click="redirect('Services')">
                {{ this.$t('user_menu.service_access')}}
              </div>
              <div class="element hovered" @click="redirect('Groups')">
                {{ this.$t('user_menu.data_access')}}
              </div>
              <div v-if="roles.includes('superadmin') || roles.includes('developer')" class="element hovered"
                @click="redirect('SearchRequests')">
                {{ this.$t('user_menu.statistics')}}
              </div>
              <div v-if="!roles.includes('operator')" class="element hovered" @click="redirect('Logs')">
                {{ this.$t('user_menu.logs')}}
              </div>
            </div>



            <div class="menuHeader">
              <i class="feather icon-info"></i>{{ this.$t('user_menu.about_the_program')}}
            </div>
            <div class="element hovered" style="margin-top: 0 !important;" @click="redirect('Description')">
              {{ this.$t('user_menu.data_sources_services')}}
            </div>
            <div class="element hovered" @click="redirect('DescriptionBases')">
              {{ this.$t('user_menu.data_sources_databases')}}
            </div>
            <div class="element hovered" @click="redirect('ServerChangelog')">
              {{ this.$t("user_menu.whats_new")}}
            </div>
            <div class="element hovered" v-if="(roles.includes('developer') || roles.includes('superadmin')) && dev"
              @click="redirect('Documentation')">
              {{ this.$t("pages.Documentation")}}
            </div>
            <div class="element hovered" @click="openFeedbackModal">
              {{ this.$t("ui.feedback")}}
            </div>
            <div class="menuHeader hovered" @click.prevent="logout()">
              <i class="feather icon-log-out"></i>{{ this.$t("user_menu.exit")}}
            </div>
          </ul>
        </div>

      </li>
      <notificationHeader v-if="permissions.includes('monitoring.location')" />
      <li class="header-notification language-select" v-if="this.$route.name === 'Home'">
        <div class="dropdown-primary dropdown">
          <a href="#" data-bs-toggle="dropdown" class="dropdown-toggle text-white text-uppercase">{{ $i18n.locale }}<i
              class="feather icon-chevron-down"></i></a>
          <ul class="dropdown-menu show-notification profile-notification text-center"
            style="width: 6em; min-width: 6em;">
            <li v-for="lang in langs" :key="lang">
              <a href="#" @click.prevent="setLang(lang)" class="text-uppercase p-0">{{ lang }}</a>
            </li>
          </ul>
        </div>
      </li>
      <b-modal id="feedback-moodal" centered ref="feedback-modal" hide-footer :title="$t('ui.feedback')">
        <feedback @feedbacked="closeModalFeedback" />
      </b-modal>


      <b-modal class="balance-modal" centered ref="balance-modal" hide-footer :title="$t('users_list_params.balance')">
        <div style="display: flex;flex-direction: column;padding-top: 5px;padding-bottom: 16px">
          <div class="modalText" :class="{'alertText' : balanceAlertCheck, 'errorText': balanceErrorCheck }"
            v-if="!user.account.unlimited">{{ $t('users_list_params.balance') }}: {{ user.account.balance }}</div>
          <div class="modalText" v-else>{{ $t('users_list_params.balance') }}: {{ $t('users_list_params.unlimited') }}
          </div>
          <div class="modalText" :class="{'alertText' : limitAlertCheck, 'errorText': limitErrorCheck  }">{{
            $t('users_list_params.limit') }}: {{ limitLeft() }} {{ $t('users_list_params.to') }} {{limitExpiredDate |
            moment('DD.MM.YYYY, HH:mm')}} </div>
          <!--          <div class="modalText" :class="{'alertText' : expiredAlertCheck }">{{ $t('users_list_params.account_expiration_date') }}: {{ user.account.expired_at | moment('DD.MM.YYYY, HH:mm')}}</div>-->



          <div class="modalText" :class="{'alertText' : expiredAlertCheck }" v-if="!this.roles.includes('superadmin')">
            {{ $t('users_list_params.account_expiration_date') }}: {{ moment(user.account.expired_at, 'X').format('DD.MM.YYYY, HH:mm')}}</div>
          <div class="modalText" v-else>{{ $t('users_list_params.account_expiration_date') }}: {{
            $t('users_list_params.unlimited')}}</div>


        </div>
      </b-modal>
    </ul>
  </div>

</template>

<script>
import { mapGetters, mapState } from "vuex";
import env from "../../configs/env.json";
import Feedback from "@/components/Feedback.vue";
import moment from "moment";
import notificationHeader from '@/components/layouts/notificationHeader.vue'

export default {
  name: "userNav",
  components: {Feedback, notificationHeader},
  setup() {
    return {
      moment
    }
  },
  data() {
    return {
      prefix: env.prefix,
      dev: env.dev,
      langs: [
        'en', 'ua', 'ru'
      ],
      logoutError: '',
      confirmValue: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
    ...mapGetters("auth", ["user","roles"]),
    ...mapGetters("contacts", ["searchesCount"]),
    isAdminAccess() {
      return this.roles.includes('admin')
          || this.roles.includes('developer')
          || this.roles.includes('frontliner')
          || this.roles.includes('superadmin')
    },

    expiredAlertCheck(){
      let limit = moment().startOf('day');
      let exp = moment(this.user.account.expired_at, 'X')
      let diff = exp.diff(limit,"days")
      return diff <= 10
    },
    balanceAlertCheck(){
      if (this.user.account.unlimited){
        return false
      } else {
        return this.user.account.balance <= 50 && this.user.account.balance > 0
      }
    },
    balanceErrorCheck(){
      if (this.user.account.unlimited) {
        return false
      } else {
        return this.user.account.balance <= 0
      }
    },
    limitAlertCheck(){
      let limit = this.limitLeft()
      return limit <= 50 && limit >= 0
    },
    limitErrorCheck(){
      let limit = this.limitLeft()
      return limit <= 0
    },
    limitExpiredDate(){
      let exp = moment(this.user.account.reseted_at, 'X')
      return  exp.add(this.user.account.period,'days')
    },

    iconState(){
       if (this.balanceErrorCheck || this.limitErrorCheck){
          return 'error'
       } else if (this.expiredAlertCheck || this.balanceAlertCheck || this.limitAlertCheck){
          return 'alert'
       } else {
         return 'success'
       }
    }

  },
  methods:{
    limitLeft(){
      return this.user.account.limit - this.user.account.counter
    },
    redirect(name){
      this.$router.push({name})
    },
    closeModalFeedback() {
      this.$refs['feedback-modal'].hide();
    },
    openFeedbackModal() {
      this.$refs['feedback-modal'].show();
    },
    openBalanceModal() {
      this.$refs['balance-modal'].show();
    },
    logout() {
      this.$store.dispatch("auth/logoutActionButton").then(() => {
        // this.clearCache();
        this.$store.dispatch("contacts/clearLogin");
        return this.$router.push({ name: "Login" });
      });
    },
    setLang(lang) {
      this.$root.$i18n.locale = lang;
      localStorage.setItem('currentLanguage', lang)
      $(document).trigger('language');
    },

  }
}
</script>

<style scoped lang="scss">
.iconShadow {
  border-radius: 20px;
  margin: 0;
  padding: 0;
}
.green {
  -webkit-box-shadow: 0 0 5px 3px #00ff00;
  -moz-box-shadow: 0 0 5px 3px #00ff00;
  box-shadow: 0 0 5px 3px #00ff00;
}
.red {
  -webkit-box-shadow: 0 0 5px 3px #ff0000;
  -moz-box-shadow: 0 0 5px 3px #ff0000;
  box-shadow: 0 0 5px 3px #ff0000;
}
.alert {
  -webkit-box-shadow: 0 0 5px 3px #fac800;
  -moz-box-shadow: 0 0 5px 3px #fac800;
  box-shadow: 0 0 5px 3px #fac800;
}
.textInfo {
  padding: 2px 8px;
}
.modalText {
  //padding: 2px 16px;
}
.alertText {
  background-color: #fac800;
}
.errorText {
  background-color: #ff0000;
}
//.modal-body {
//  //margin: -16px !important;
//  .alertText {
//    background-color: rgba(250, 200, 0, 0.15);
//  }
//  .errorText {
//    background-color: rgba(255, 0, 0, 0.15);
//  }
//}
.modal-body {
  padding: 0 !important;
}
.nav-left{
  color: white;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
  width: 20em;
}
.menuHeader {
  vertical-align: center;
  padding: 0.5em 20px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  line-height: 1rem;
}
.element {
  line-height: 1rem;
  padding-left: 4em;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  margin-top: 0 !important;
}
.hovered:hover{
  background-color: #f1f1f1;
}
.icon-chevron-down:before{
  color: white;
}
.icon{
  border: 1px solid #eceeef;
  border-radius: 3px;
  color: rgba(43,61,81,.7);
  display: inline-block;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  margin: 0 12px 0 0;
  text-align: center;
  vertical-align: middle;
  width: 50px;
}

.nav-right {
  background-color: #263544;
}

// body .header-navbar .navbar-wrapper .navbar-container .nav-left li,
// body .header-navbar .navbar-wrapper .navbar-container .nav-right li {
//   line-height: 77px;
// }
</style>