import { get, last } from "underscore";
import axios from "../../configs/axios";
import env from '../../configs/env.json';

let host = '';
if(location.hostname.includes('web')) {
    host = location.hostname.replace('web.', '')
    env.monitoringEndpoint = `https://monitoring.${host}/api`
}
const domain = env.monitoringEndpoint;



export default {
    namespaced: true,
    state: {
        routes: {},
        areas: [],
        areaLogs: [],
        crossTrackingTasks: [],
        crossTrackingLogs: {},
        places: [],
        watchedAreas: [],
        lastTimeWatchedAreas: null,
        lastTimeCrossTrackingLogs: null,
        notificationAreas: [],
        notificationCrossTrackingTasks: [],
        notificationAreasCount: 0,
        notificationCrossTrackingTasksCount: 0,
        notificationMonitoring: null,
        areaLogsPagination: {},
        placesDetails: {}
    },
    getters: {
        routes: state => state.routes,
        areas: state => state.areas,
        crossTrackingTasks: state => state.crossTrackingTasks,
        crossTrackingLogs: state => state.crossTrackingLogs,
        areaLogs: state => state.areaLogs,
        places: state => state.places,
        domain: () => domain,
        watchedAreas: state => state.watchedAreas,
        lastTimeWatchedAreas: state => state.lastTimeWatchedAreas,
        lastTimeCrossTrackingLogs: state => state.lastTimeCrossTrackingLogs,
        notificationAreas: state => state.notificationAreas,
        notificationCrossTrackingTasks: state => state.notificationCrossTrackingTasks,
        notificationAreasCount: state => state.notificationAreasCount,
        notificationCrossTrackingTasksCount: state => state.notificationCrossTrackingTasksCount,
        notificationMonitoring: state => state.notificationMonitoring,
        areaLogsPagination: state => state.areaLogsPagination,
        placesDetails: state => state.placesDetails
    },
    actions: {
        unsubscribe({commit}, payload){
            return new Promise((resolve, reject) => {
                axios({url: `/subscription/${payload}`, baseURL: domain, method: 'DELETE' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        updateSubscription({commit}, payload){
            let innerPayload = JSON.parse(JSON.stringify(payload))
            delete innerPayload.id
            return new Promise((resolve, reject) => {
                axios({url: `/subscription/${payload.id}`, data: innerPayload, baseURL: domain, method: 'PUT' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        updateRouteTask({commit}, payload){
            let innerPayload = JSON.parse(JSON.stringify(payload))
            delete innerPayload.id
            return new Promise((resolve, reject) => {
                axios({url: `/monitoring_task/${payload.id}`, data: innerPayload, baseURL: domain, method: 'PUT' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        stopRouteTask({commit}, payload){
            return new Promise((resolve, reject) => {
                axios({url: `monitoring_task/${payload}/stop`, baseURL: domain, method: 'GET' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },



        createMonitoringTask({commit}, payload){
            let innerPayload = JSON.parse(JSON.stringify(payload))
            delete innerPayload.id
            if (!innerPayload.period){
                delete innerPayload.period
            }
            if (innerPayload.service_ids && innerPayload.service_ids.length === 0){
                delete innerPayload.service_ids
            }

            return new Promise((resolve, reject) => {
                axios({url: `/monitoring_task/${payload.id}`, data: innerPayload, baseURL: domain, method: 'POST' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createCrossTrackingTask({commit}, payload){

            return new Promise((resolve, reject) => {
                axios({url: 'locations/cross_tracking_task', data: payload, baseURL: domain, method: 'POST' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },



        getTargetsLocations({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'locations', data: payload, baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setTargetsLocations', resp.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        getAreas({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'locations/area_tracking_tasks', data: payload,baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setAreas', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        getWatchedAreas({commit}) {
            const payload = {
                filter: {
                    alert_status: true
                }
            }
            return new Promise((resolve, reject) => {
                axios({url: 'locations/area_tracking_tasks', data: payload,baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setWatchedAreas', resp.data.data)
                        resolve(resp.data.data);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        getCrossTrackingTasks({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'locations/cross_tracking_tasks',  baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setCrossTrackingTasks', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        getCrossTrackingTasksLogs({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'locations/cross_tracking_logs', data: JSON.parse(JSON.stringify(payload)),  baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setCrossTrackingLogs', resp.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },

        crossTrackingTasksLogsPagination({commit}, payload){
            return new Promise((resolve, reject) => {
                axios({url: 'locations/cross_tracking_logs', data: payload, baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setCrossTrackingLogsPagination', resp.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },


        clearCrossTrackingTasksLogs({commit}){
            commit('setClearCrossTrackingTasksLogs')
        },
        updateCrossTrackingTask({commit}, payload){
            let innerPayload = JSON.parse(JSON.stringify(payload))
            delete innerPayload.task_id
            return new Promise((resolve, reject) => {
                axios({url: `locations/cross_tracking_task/${payload.task_id}`, data: innerPayload, baseURL: domain, method: 'PUT' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },





        targetLocationsPagination({commit}, payload){
            return new Promise((resolve, reject) => {
                axios({url: 'locations', data: payload, baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setTargetsLocationsPagination', resp.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        clearTargetsLocations({commit}){
            commit('setClearTargetsLocations')
        },
        createArea({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'locations/area_tracking_task', data: payload,baseURL: domain, method: 'POST' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateArea({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: `locations/area_tracking_task/${payload.id}`, data: payload.payload,baseURL: domain, method: 'PUT' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        areaTrackingLogs({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'locations/area_tracking_logs', data: JSON.parse(JSON.stringify(payload)),baseURL: domain, method: 'POST' })
                    .then(resp => {
                        if(payload.offset > 0 ) {
                            commit('setAreasLogsPaginated', resp.data)
                        } else {
                            commit('setAreasLogs', resp.data)
                        }
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getVisitedPlaces({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'locations/clusters', data: payload,baseURL: domain, method: 'POST' })
                    .then(resp => {
                        resolve(resp);
                        commit('setPlaces', resp.data)
                        commit('clearPlacesDetails')
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        setEmptyPlaces({commit}) {
            commit('setPlaces', {data: []})
        },
        getNotificationAreas({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'locations/area_tracking_logs', data: payload,baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setNotificationAreasCount', resp.data.total)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        getNotificationCrossTrackingTasks({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'locations/cross_tracking_logs', data: payload,baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setNotificationCrossTrackingTasksCount', resp.data.total)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        openNotificationDetail({commit}, payload) {
            commit('setNotification', payload);
        },
        getPlacesDetails({commit}, {payload, id}) {
            return new Promise((resolve, reject) => {
                axios({url: `locations/cluster/${id}/periods`, data: payload, baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setPlacesDetails', {place: resp.data, id})
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            }) 
        }
    },
    mutations: {
        setTargetsLocations(state , targetsLocations){
            state.routes = targetsLocations
        },
        setCrossTrackingTasks(state, crossTrackingTasks){
            let tempArr = []
            crossTrackingTasks.forEach(task=> {
                task.targets.sort(function (a, b) { return a.target_id - b.target_id; })
                tempArr.push(task)
            })
            state.crossTrackingTasks = tempArr
        },
        setCrossTrackingLogs(state, crossTrackingLogs){
            let tempArr = []
            crossTrackingLogs.data.forEach((log, index) => {
                log.id = index + 1
                log.locations.sort(function (a, b) { return a.target_id - b.target_id; })
                tempArr.push(log)
            })
            let logs = {
                count: crossTrackingLogs.count,
                data: tempArr,
                total: crossTrackingLogs.total
            }
            state.crossTrackingLogs = logs
        },
        setCrossTrackingLogsPagination(state, crossTrackingLogs){
            let existLogs = state.crossTrackingLogs.data
            let tempArr = []
            let lustIndex = existLogs.length
            crossTrackingLogs.data.forEach((log, index) => {
                log.id = index + lustIndex + 1
                log.locations.sort(function (a, b) { return a.target_id - b.target_id; })
                tempArr.push(log)
            })
            let allLogs = existLogs.concat(tempArr)
            let logs = {
                count: state.crossTrackingLogs.count + crossTrackingLogs.count,
                data: allLogs,
                total: crossTrackingLogs.total
            }
            state.crossTrackingLogs = logs
        },
        setClearCrossTrackingTasksLogs(state){
            state.crossTrackingLogs = []
        },
        setTargetsLocationsPagination(state, targetLocations){
            let existPoints = state.routes.data
            let allPoints = existPoints.concat(targetLocations.data)
            let routes = {
                count: state.routes.count + targetLocations.count,
                data: allPoints,
                total: targetLocations.total
            }
            state.routes = routes
        },
        setClearTargetsLocations(state){
            state.routes = []
        },
        setAreas(state, areas){
            state.areas = areas
        },
        setAreasLogs(state, logs){
            state.areaLogs = logs.data
            state.areaLogsPagination = {
                total: logs.total,
                count: logs.count
            }
        },
        setAreasLogsPaginated(state, logs) {
            state.areaLogs = state.areaLogs.concat(logs.data)
            state.areaLogsPagination = {
                total: logs.total,
                count: state.areaLogsPagination.count + logs.count
            }
        },
        setPlaces(state, places){
            state.places = places.data
            state.placesPagination = {
                total: places.total,
                count: places.count
            }
        },
        setWatchedAreas(state, areas){
            state.watchedAreas = areas;
        },
        setNotificationAreasCount(state, count){
            state.notificationAreasCount = count
        },
        setNotificationCrossTrackingTasksCount(state, count){
            state.notificationCrossTrackingTasksCount = count
        },
        setNotification(state, payload){
            state.notificationMonitoring = payload
        },
        setPlacesDetails(state, {place, id}){
            state.placesDetails[id] = place
        },
        clearPlacesDetails(state){
            state.placesDetails = {}
        }
    }
}

