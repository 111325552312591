import axios from "../../configs/axios";
import moment from "moment";
import { isUndefined } from "underscore";

function tokenSave(commit, resp) {
    const token = 'Bearer ' + resp.data.token;
    window.localStorage.setItem('token', token);
    localStorage.setItem('loginDate', moment().format('x'));
    axios.defaults.headers.common['Authorization'] = token;
    if(!isUndefined(resp.data.secret)) {
        localStorage.setItem('secretHash', resp.data.secret)
    }
    commit('auth_success', {
        token,
        roles: typeof resp.data.roles !== 'undefined' ? resp.data.roles : null
    });
}

function fetchVersion(state) {
    const updaterLink = 'https://update-20.com/api';
    fetch(`${updaterLink}/update`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "app_name": "GDSAndroid",
            "id": {
               "license": "production"
             },
            "version": "1.0"
        })
    }).then(async res => {
        const body = await res.json();
        state.lastApk = `${updaterLink}/download/${body.update}`
    })
}



export default {
    namespaced: true,
    state: {
        status: '',
        token: window.localStorage.getItem('token') || '',
        verificationChannels: localStorage.getItem('verificationChannels') ? JSON.parse(localStorage.getItem('verificationChannels')) :  [],
        roles : localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles')) :  [],
        user: localStorage.getItem('user')? JSON.parse(localStorage.getItem('user')): null,
        tokenUpdated: '',
        lastApk: '',
        VerificationCodeExpiredTime: {}
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        roles: state => state.roles,
        user: state => state.user,
        permissions: state => state.user.permissions || [],
        isAdmin: state => {
            return state.roles.includes('admin')
                || state.roles.includes('developer')
                || state.roles.includes('frontliner')
                || state.roles.includes('superadmin')
        },
        isTenantEditor: state => {
            return state.roles.includes('frontliner')
                || state.roles.includes('superadmin')
        },
        token: state => state.token,
        lastApk: state => state.lastApk,
        verificationChannels: state => state.verificationChannels,
        VerificationCodeExpiredTime: state => state.VerificationCodeExpiredTime
    },
    actions: {
        updateAccount({commit}, account){
            commit('setUpdateAccount', account)
        },

        signIn({commit, state}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                localStorage.setItem('login', user.login);
                if(!localStorage.getItem('token')) {
                    axios({url: 'auth/login', data: user, method: 'POST' })
                        .then(resp => {
                            tokenSave(commit, resp);
                            commit('setVerificationChannel', resp.data)
                            fetchVersion(state);
                            resolve(resp);
                        })
                        .catch(err => {
                            commit('auth_error')
                            window.localStorage.removeItem('token')
                            reject(err)
                        })
                } else {
                    resolve({'success': true})
                }
            })
        },
        logoutAction({commit}) {
            return new Promise((resolve) => {
                commit('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('roles');
                localStorage.removeItem('verificationChannels');
                resolve();
            });
        },
        logoutActionButton({commit}) {
            return new Promise((resolve) => {
                axios({method: 'GET', url: 'auth/logout'})
                commit('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('roles');
                localStorage.removeItem('verificationChannels');
                resolve();
            });
        },
        getMe({commit}) {
            return new Promise(resolve => {
                axios({url: 'auth/me', method: 'GET'})
                    .then(resp => {
                        commit('setMe', resp.data)
                        commit('setRolesFromUser', resp.data)
                        commit('contacts/setSearchLimit', resp.data.requests_count, {root: true})
                        resolve(resp.data);
                    }).catch(err => {
                        console.error(err);
                    });
            });
        },
        refreshToken({commit}) {
            if(localStorage.getItem('token')) {
                return new Promise((resolve, reject) => {
                    axios({url: 'auth/refresh', method: 'GET'})
                        .then(resp => {
                            tokenSave(commit,resp);
                            const token = 'Bearer ' + resp.data.token;
                            resolve(token);
                        }).catch(err=>{
                            reject(err)
                    })
                })
            }
        },
        addVerification({commit}, data) {
            return new Promise((resolve, reject) => {
                // setTimeout(() => {resolve({success:true}, 5000)})
                axios({url: 'user/set_verification_channel', method: 'POST', data})
                    .then(resp => {
                        resolve(resp.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        sendVerificationCode({commit}){
            return new Promise( (resolve, reject) => {
                axios({url: 'auth/send_code', method: 'GET',  params: { channel: 'telegram' }})
                    .then(resp => {
                        commit('setVerificationCodeExpiredTime', resp.data)
                        resolve(resp.data);
                    }).catch(err => {
                        reject(err)
                });
            })
        },
        verify({commit}, code) {
            return new Promise((resolve, reject) => {
                axios({url: 'auth/verify',data: {code}, method: 'POST'})
                    .then(resp => {
                        resolve(resp.data);
                    }).catch(err => {
                    reject(err)
                });
            })
        },

    },
    mutations: {
        setVerificationChannel(state, resp){
            state.verificationChannels = resp.verification_channels;
            localStorage.setItem('verificationChannels', JSON.stringify(resp.verification_channels));
        },
        setVerificationCodeExpiredTime(state, resp){
            state.VerificationCodeExpiredTime = resp;
        },
        setUpdateAccount(state, account){
            state.user.account = account;
            localStorage.setItem('user', JSON.stringify(state.user));
        },
        auth_request(state){
            state.status = 'loading'
        },
        auth_success(state, payload){
            state.status = 'success';
            state.token = payload.token;
            state.tokenUpdated = moment().format('X');
            if(payload.roles) {
                state.roles = payload.roles;
                localStorage.setItem('roles', JSON.stringify(payload.roles));
            }
        },
        auth_error(state){
            state.status = 'error'
        },
        logout(state){
            state.status = '';
            state.token = '';
            state.roles = [];
            state.user = {};
            localStorage.removeItem('user')
            localStorage.removeItem('roles');
        },
        setMe(state, payload) {
            state.user = payload;
            localStorage.setItem('user', JSON.stringify(payload));
        },
        setRolesFromUser(state, payload){
            state.roles = payload.roles;
            localStorage.setItem('roles', JSON.stringify(payload.roles));
        },
    }
}

